import { App } from './app';

export class Section {

    public id: number;
    public name: string;
    public app: App;

    constructor(_id, _name)
    {
        this.id = _id;
        this.name = _name;
    }

}

