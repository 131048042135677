export class menuObject { 
    public id: number;
    public type: string;
    public icon: String;
    public icon_img: String;
    public title: string;
    public picture: string;
    public link: string;
    public style: string;
    public textstyle: string;
  }