import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { AudioShow } from '../../../../../shared/models/audioShow';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-audio-episodes',
  templateUrl: './admin-audio-episodes.component.html',
  styleUrls: ['./admin-audio-episodes.component.scss']
})
export class AdminAudioEpisodesComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Alla program';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public episodes: any[] = [];
  public audioShow: AudioShow;

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient,
    private route: ActivatedRoute, private router: Router, private api: ApiService, private translate: TranslateService) {

  }

  translateMe(key:string) {
    let word = ""
    this.translate.get(key, { value: ""}).subscribe((res: string)=> {word = res})
    return word
  }

  ngOnInit(): void {

    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getAudioShow(id).subscribe(data => {
      this.audioShow = data;
    })

    const that = this;

    let appstr = "";

    this.dtOptions = {
      order: [[ 0, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     `${this.translateMe("global.the-table-is-empty")}`,
        "info":           "Showing row _START_ - _END_ out of _TOTAL_ rows",
        "infoEmpty":      "Show 0 of 0 rows",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     `${this.translateMe("global.view")} _MENU_ ${this.translateMe("global.rows-per-page")}`,
        "loadingRecords": "Loading...",
        "processing":     "Loading...",
        "search":         `${this.translateMe("global.search")}`,
        "zeroRecords":    "No content found",
        "paginate": {
          "first":      `${this.translateMe("global.first")}`,
          "last":       `${this.translateMe("global.last")}`,
          "next":      `${this.translateMe("global.next")}`,
          "previous":   `${this.translateMe("global.previous")}`,
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/audio-episodes?audioshow_id=' + id + '&customer_id=' + this.data.currentUser.customer.id + "&appstr=" + appstr,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.episodes = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }]
    };
  }

  ngOnDestroy(): void {
  }

  sync(episode_id, show_id)
  {

    this.api.syncAudioShow(episode_id, show_id).subscribe(() => {

      for (var i = this.episodes.length - 1; i >= 0; --i) {
        if (this.episodes[i].id == episode_id) {
          this.episodes.splice(i,1);
        }
    }

    })

  }


}
