import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { Response } from '../../../../../shared/models/response';
import { User } from '../../../../../shared/models/user';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  public email:string;
  public password: string;
  public loggedin: Boolean;
  public mode:number = 0;
  public response = "";
  public token: string;
  private user: User;

  constructor(private router: Router,
    private api: ApiService,
    private data: DataService,
    private route: ActivatedRoute) {

      this.token = this.route.snapshot.queryParamMap.get("token");

      if (this.token)
      {
        this.api.getUserByToken(this.token).subscribe((data: User) => {
          if (data)
          {
            this.user = data;
            this.mode = 2;
          }
        })
      }

      let id = +localStorage.getItem("id");

      if (!id)
      {
        id = +sessionStorage.getItem("id");
      }

      if (id)
      {
        this.api.getUser(id).subscribe((data: User) => {
          this.data.currentUser = data;
          let url = sessionStorage.getItem("url");

          if (url)
          {
            this.router.navigateByUrl(url);
          }
          else
          {
            this.router.navigateByUrl('');
          }

        });
      }

  }

  ngOnInit() {
  }

  login() {

    if (!this.email)
    {
    //  this.email = "linusanderas@gmail.com";
    //  this.password = "lilalilo00!";
    }

    this.api.login(this.email, this.password).subscribe((data: Response) => {
      if (data.code == 200)
      {
          if (this.loggedin)
          {
            localStorage.setItem("id", data.id.toString());
          }

        sessionStorage.setItem("id", data.id.toString());
        this.data.apiToken = data.apitoken;

        this.api.getUser(data.id).subscribe((data: User) => {
          this.data.currentUser = data;

          if (data.isEmployee == 1)
          {
            if (data.isJournalist == 1)
            {
              this.router.navigateByUrl('');
              //this.router.navigateByUrl('/intranet/news-stats');
            }
            else
            {
              this.router.navigateByUrl('/intranet/start');
            }
          }
          else
          {
            this.router.navigateByUrl('');
          }
        });
      }
      else
      {
        alert(data.message);
      }
    })



  }

  changeMode(m) {
    this.mode = m;
  }

  restore(){

    this.api.send_restore(this.email).subscribe((data:Response) => {
      if (data.message == "NOT_FOUND")
      {
        this.response = "Eposten finns inte registerad i vårt system. Kontrollera stavningen.";
      }
      else if (data.message == "SENT")
      {
        this.response = "Ett meddelande med en återställningslänk har skickats till din epost.";
      }
    })

  }

  setPassword()
  {
    this.user.password = this.password;
    this.api.setUser(this.user).subscribe((data: Response) => {
      this.email = this.user.email;
      this.login();
    })
  }


}
