import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../../../../shared/models/user';
import { ApiService } from 'src/app/Services/api.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.sass']
})
export class AdminUsersComponent implements OnInit, OnDestroy {
  baseUrl:string = environment.apiUrl;
  heading = 'Användare';
  subheading = 'Här redigerar du kundernas användare.';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public users: User[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient, private translate: TranslateService) {
  }

  translateMe(key:string) {
    let word = ""
    this.translate.get(key, { value: ""}).subscribe((res: string)=> {word = res})
    return word
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     `${this.translateMe("global.the-table-is-empty")}`,
        "info":           "Showing row _START_ - _END_ out of _TOTAL_ rows",
        "infoEmpty":      "Show 0 of 0 rows",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     `${this.translateMe("global.view")} _MENU_ ${this.translateMe("global.rows-per-page")}`,
        "loadingRecords": "Loading...",
        "processing":     "Loading...",
        "search":         `${this.translateMe("global.search")}`,
        "zeroRecords":    "No content found",
        "paginate": {
          "first":      `${this.translateMe("global.first")}`,
          "last":       `${this.translateMe("global.last")}`,
          "next":      `${this.translateMe("global.next")}`,
          "previous":   `${this.translateMe("global.previous")}`,
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/users?uid=' + this.data.currentUser.id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.users = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'name' }, { data: 'email' }, { data: 'role' }, { data: 'lastseen' }]
    };

  }

  ngOnDestroy(): void {
  }
}
