import { App } from './app';
import { Customer } from './customer';
import { Byline } from './byline';
import { Device } from './device';

export class User {

    public id: number;
    public username: String;
    public email: string;
    public password: String;
    public role: string;
    public name: String;
    public token: String;

    public avatar: string;

    public apps: App[] = [];
    public selectedApps: App[] = [];
    public selectedEmployees: User[] = null;
    public customers: Customer[] = [];
    public available_customers: Customer[] = [];

    public customer: Customer; // Current Customer;

    public isJournalist: number;
    public isEmployee: number;
    public isColumnist: number;

    public columnistText: string;
    public columnistApps: App[] = [];

    public default_byline: Byline;
    public default_app: App;
    public default_startword: string;

    public lastseen: string;

    public status: number;

    public active_plan: string;

    public devices: Device[];
    public maxdevices: number;



}

