import { Customer } from "./customer";
import { User } from "./user";

export class CustomerLog {

    public id: number;
    public user_id: number;
    public user: User;
    public customer_id: number;
    public customer: Customer;
    public msg: string;
    public type: string;
    public time: string;

}

