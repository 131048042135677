import { App } from './app';
import { CustomerLog } from './customer_log';
import { Media } from './media';
import { ProfilePlace } from './profile_place';
import { Section } from './section';
import { Template } from './template';
import { User } from './user';

export class Customer {

    public id: number;
    public name: string;
    public logo: string;
    public name_and_city: string;
    public profile_places: ProfilePlace[];
    public christmas_images: Media[] = [];
    public show: string;
    public status: number;
    public apps: App[] = [];
    public users: User[] = [];
    public sellers: User[] = [];
    public supporters: User[] = [];
    public app: App;
    public city: string;
    public alternative_name: string;
    public sections: Section[] = [];
    public template_full: Template;
    public template_feed: Template;
    public hasLunch: string;
    public hasQuiz: string;
    public hasNewsAd: string;
    public hasTakeOverAd: string;
    public hasWorkAd: string;
    public hasBooliAd: string;
    public hasMenu1Ad: string;
    public hasMenu2Ad: string;
    public hasIBecon: string;
    public hasProducts: string;
    public hasNotifyAll: string;
    public hasStudenOffers: string;
    public hasChristmas: string;
    public hasEvents: string;
    public hasPositionAd: string;
    public hasTemplates: string;
    public parent: Customer;
    public contracts_end: string;
    public contracts_value: number;
    public contracts_id: number;
    public support_mail:string;
    public lunch_order: number;

    public logs: CustomerLog[] = [];

    public setting_profiletime: number;
    public number_ads: number;
    public score: number;

    public email: string;
    public showroom_mail: string;

    public phone: string;

    public following: boolean;
    public distance: number;

}

