import { AdTag } from './adtag';
import { App } from './app';
import { Media } from './media';
import { Serie } from './serie';
import { Byline } from './byline';
import { Template } from './template';
import { User } from './user';

export class Ad {

    public id: number;
    public title: String;
    public alt_title: String;
    public status: number;
    public body: String;
    public click: number;
    public views: number;
    public diff: number;
    public unique_views: number;
    public shortbody: String;
    public timestart: string;
    public timestop: string;
    public timeupdate: String;
    public preword: String;
    public customer_id: number;
    public category: String;
    public apps: App[] = [];
    public medias: Media[] = [];
    public series: Serie[] = [];
    public byline: Byline;
    public isNewsAd: string;
    public isWorkAd: string;
    public isBooliAd: string;
    public isMenu1Ad: string;
    public isMenu2Ad: string;
    public isTakeOverAd: string;
    public isProfileAd: string;
    public isRandomOrder: string;
    public isPositionAd: string;
    public positionTitle: string;
    public positionText: string;
    public isFree: string;
    public externallink: string;
    public reactivates: any[];
    public clicked: string;
    public vinjett: string;
    public notify: string;
    public notifyAll: string;
    public isStudentOffer: string;
    public isOffer: string;
    public user_id: number;
    public template_full: Template;
    public template_feed: Template;
    public tags: AdTag[] = [];
    public columnist: User;
    public columnist_id: number;
    public link_base: string;
    public link_id: number;
    public isSummary: number;
    public links: string[];


}

