import { Device } from './device';

export class AppUser {

    public id: number;
    public email: string;
    public password: string;
    public name: string;
    public maxdevices: string;
    public active_plan: string;
    public period_end: string;
    public canceled: number;
    public stripe_id: string;
    public devices: Device[];

}

