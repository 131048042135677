import { Media } from './media';

export class Byline {

    public id: number;
    public name: string;
    public media: Media;
    public content: string;

}

